import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "New York",
  "subtitle": "-",
  "category": "USA"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "new-york-city"
    }}>{`New York City`}</h2>
    <p>{`Better to just ask me... This particular list isn't updated very often`}</p>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qE4MXjMBQ9A1t6WF7"
        }}>{`Boqueria`}</a>{` - Tapas`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ZDQA8hAUhGgBiBiW6"
        }}>{`Chama Mama`}</a>{` - Georgian staples like Khachapuri`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/RCDyXcoPMULjA1cN8"
        }}>{`NONONO`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NwozbqkZWy416cCe8"
        }}>{`Raku`}</a>{` - Udon`}</li>
    </ul>
    <h4 {...{
      "id": "bbq"
    }}>{`BBQ`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.donsbogam.com/"
        }}>{`Dons Bogam`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/U3ytcusXUcC162NQ8"
        }}>{`Jongro BBQ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zrdxHZ3szNYDRb7R8"
        }}>{`Yakiniku Futago`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wzFgi4gqBUT3ufC3A"
        }}>{`Yoon Haeundae Galbi`}</a></li>
    </ul>
    <h4 {...{
      "id": "street-food"
    }}>{`Street food`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VC6q1R7Mkztjb1eA9"
        }}>{`Cheong Fun Cart`}</a>{` - breakfast only`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/5fvCwRLmiDMqbVdFA"
        }}>{`The Halal Guys`}</a></li>
    </ul>
    <h4 {...{
      "id": "cafes-desserts-and-tea-houses"
    }}>{`Cafes, Desserts and Tea houses`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nTXrKtVx3NVEPNPb6"
        }}>{`Cha An`}</a>{` - Japanese tea house I frequented when I was in school`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/rDuJ8j7zUCF42PsT6"
        }}>{`Chinatown Ice Cream Factory`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zDzb1nWMs6isMdJd8"
        }}>{`Eileen's Cheesecake`}</a>{` - Hands down best cheesecakes`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/E7TUQSUA2bB629EP6"
        }}>{`Janie's Life-Changing Baked Goods`}</a>{` - Pie crust cookies`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1psaqxnAyvGJwaAi7"
        }}>{`Kam Hing Coffee Shop`}</a>{` - Their spongecakes are the best`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PzJ8sAr5yr8nA5Do6"
        }}>{`Keki Modern Cake`}</a>{` - Japanese cheesecakes and tarts`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/k84FgaHTBZhHGUmPA"
        }}>{`Soft Swerve`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/n9cCe7EZoVfTTsJ57"
        }}>{`Sundaes and Cones`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.playbill.com/"
        }}>{`Broadway Shows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Mpn2AKzzrbm7cfCr8"
        }}>{`Brooklyn Bridge`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Recommended route: Start on the Manhattan side walk across the bridge to `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/jy28YcpcDQXn1Ftt5"
            }}>{`Dumbo`}</a>{`. Explore the area for a bit and then take the `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/CFhB7ZCq7GiGW36A6"
            }}>{`Manhattan Bridge`}</a>{` (from the Brooklyn side). It will lead to Manhattan's Chinatown where you can then eat dinner!`}</li>
          <li parentName="ul">{`The Manhattan Bridge is loud due to the subway. It's also fenced so there's an obstructed view. However, there are a lot of "gaps" where you can take some great photos of lower Manhattan.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/pZ1fBcg6EUvcGo939"
        }}>{`Flatiron Building`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/xYqUqje6uSAS2C9R7"
        }}>{`Grand Central Station`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/buvYiPdihHGLw5SVA"
        }}>{`Macy's`}</a>{` - Iconic`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QSCpXo6kKGq3rkRQA"
        }}>{`NYPL Schwarzman Building`}</a>{` - This library branch is special`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/h9RdJ1u8aj1ke88d6"
        }}>{`The High Line`}</a>{` - Stroll along the west side between Hudson Yards and Chelsea Market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/5jwREBjyUj8HNJUV6"
        }}>{`Statue of liberty`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You can get to this island via a paid ferry`}</li>
          <li parentName="ul">{`If you just some photos from afar, take the `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/8bE9vYGaU9wZG1qj9"
            }}>{`Staten Island Ferry`}</a>{` which is free in both directions`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WGKHQMhZRZCRpfVUA"
        }}>{`Union Square Park`}</a>
        <ul parentName="li">
          <li parentName="ul">{`There's always something happening here. Always.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/ix4o98BxViN8J1Bn9"
            }}>{`Greenmarket`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/6ouRSCNJFLXGdrNcA"
            }}>{`Holiday Market`}</a>{` - only at the end of the year`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/rN8k53XLTAP5uXLSA"
            }}>{`Whole Foods`}</a>{` - Across the street; 2nd floor offers park views. The DSW building next to it as well.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Sf5A2iwGNxgnjALK7"
        }}>{`Little Island`}</a>{` - Near the Whitney Museum`}</li>
    </ul>
    <h4 {...{
      "id": "museums"
    }}>{`Museums`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/53kFqmME6ZG99Gzw9"
        }}>{`9/11 Memorial & Museum`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/KiBG9ZJ5VY4Jv1hb8"
        }}>{`Guggenheim`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zPCxedbw3qdV7mRx7"
        }}>{`MoMA PS1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AhTxg6hzQEkQ7VqS7"
        }}>{`MoMA`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/jxbf7yyDbTZnn7nf8"
        }}>{`Museum of Natural History`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/92gYxyvMVERGLqL27"
        }}>{`New York Transit Museum`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/brZkkNTpfXFmSPbF6"
        }}>{`The MET`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/thSTBhbmv9ZKKTzn9"
        }}>{`Whitney`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      